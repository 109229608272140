<template>
    <div>
        <v-row justify="space-between">
            <v-col cols="12" md="6">
                <v-btn @click="getBrands">public</v-btn>
                <v-btn @click="getCarBrands">carbrand</v-btn>
                <DanawaCar @changeDanawaModel="changeDanawaModel" />
            </v-col>
            <v-col cols="12" md="6">
            <ModealCar />
            </v-col>
        </v-row>
        <v-footer fixed app>
            <v-card>
                <v-btn @click="setModels">다나와모델->모두가모델 넣기 </v-btn>
                <p>
                    다나와모델: {{$store.getters.danawa.model.name}}
                </p>
                <p>
                    모두가모델: {{$store.getters.modeal.model.name}}
                </p>
            </v-card>
        </v-footer>
       
    </div>

</template>

<script>
import ModealCar from './ModealCar'
import DanawaCar from './DanawaCar'

  export default {
    name: 'HelloWorld',
    components: {
       ModealCar,
       DanawaCar,

     // CarCategory,
    //  JSCode,
     // PayloadCode,
      // CarOptions

    },
    data () {
      return {
        danawa: {
            model: null
        },
        overlay: false,
        tabes: null,
        tab: null,
        selected: [],
        estimate: {
        },
        payload: {
            c_code: '',
            m_code: '',
            l_code: '',
            g_code: '',
            nc_code: '',
            options: [],
            month: '036',
            km: '003',
            deposit: '0',
            apay: '30',
            totalAmt: 0,
            optionAmt: 0,
            modelAmt: 0,
            detail: false,
        },
        detail: {
            options: [],
            info: {
                m_amt: '',

            }
        },

      }
    },
    methods: {
         async getBrands() {
            this.$log.warn('getbrands')
            const requestOptions = {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                    // body: JSON.stringify(this.payload)
                };
                //this.overlay = true
                // const response = await fetch("http://localhost:5000/api/danawa/brand", requestOptions);
                const response = await fetch("http://localhost:5000/api/hello", requestOptions);

                const rv  = await response.json();
                this.$log.warn('ㄷㅏㄴㅏㅇrv', rv)
                this.items = rv.data
            
                //this.overlay = false
        },
        async getCarBrands() {
            this.$log.warn('getbrands')
            const requestOptions = {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                    // body: JSON.stringify(this.payload)
                };
                //this.overlay = true
                // const response = await fetch("http://localhost:5000/api/danawa/brand", requestOptions);
                const response = await fetch("http://localhost:5000/api/car/brand", requestOptions);

                const rv  = await response.json();
                this.$log.warn('ㄷㅏㄴㅏㅇrv', rv)
                this.items = rv.data
            
                //this.overlay = false
        },
        changeDanawaModel(val) {
            this.danawa.model = val
        },
        onchange (val) {
            this.detail = val
             this.$log.warn('m_amt_new', this.detail.info.m_amt,  this.detail)
            this.payload.modelAmt = Number(this.detail.info.m_amt)
            this.payload.totalAmt = this.payload.modelAmt
            this.payload.options = []
            this.payload.c_code =  this.detail.info.c_code
            this.payload.m_code =  this.detail.info.m_code
            this.payload.l_code =  this.detail.info.l_code
            this.payload.g_code =  this.detail.info.g_code
            this.payload.nc_code =  this.detail.info.nc_code
        },
        addcomma(val) {
            var regexp = /\B(?=(\d{3})+(?!\d))/g;
            return val.toString().replace(regexp, ',');

        },
        optionPriceSum(val, checked) {
            if (checked) {
                this.payload.optionAmt += Number(val)
            } else {
                this.payload.optionAmt -= Number(val)
            }
            this.payload.totalAmt = this.payload.modelAmt + this.payload.optionAmt

        },
        valueChanged( val) {
            const _this = this
            let checked = false
            if(_this.payload.options.indexOf(val) !== -1){
                this.$log.warn('선택됨-----------------')
                checked = true
            } else {
                this.$log.warn('선택해제됨-------------')
            }
            this.$log.warn('val', val, checked)
            for (const opt of this.detail.options) {
                if (opt.OPTION_CD === val) {
                    this.optionPriceSum(opt.ADD_OPTION_AMT, checked)
                    this.$log.warn('찾음', opt, opt.EVENT_CD_ADD1.length, _this.payload.options)
                    if (opt.EVENT_CD_ADD1.length  > 0 ) {
                        _this.payload.options =  Array.from(new Set(_this.payload.options.concat(opt.EVENT_CD_ADD1)))
                        
                        // this.$log.warn('찾음2',  _this.selected.concat(opt.EVENT_CD_ADD1))
                        //if(opt.EVENT_CD_ADD1.indexOf(val) !== -1){

                        //}
                    }
                    if (opt.EVENT_CD_ADD2.length  > 0) {
                        _this.payload.options.concat(opt.EVENT_CD_ADD2)
                        //if(opt.EVENT_CD_ADD1.indexOf(val) !== -1){

                        //}
                    }
                }
               // if(opt.EVENT_CD_ADD1.indexOf(val) !== -1){
                // this.$log.warn('val', opt.EVENT_CD_ADD1)
            }
        },
        async setModels() {
            const payload = {
                dmodelId: this.$store.getters.danawa.model.id,
                dbrandId: this.$store.getters.danawa.brand.id,
                modelId: this.$store.getters.modeal.model.id,
                brandId: this.$store.getters.modeal.model.brandId,
            }
            
            this.$log.warn('getModels')
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload)
            };
            //this.overlay = true
            const url = 'http://localhost:5000/api/modeal/model'// /${brandId}`
            const response = await fetch(url, requestOptions);

            const rv  = await response.json();
            this.$log.warn('rv', rv)
            this.items = rv.data
           
            //this.overlay = false
      }

        

    }
  }
  

</script>
