<template>
  <div>
    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        

        single-select
        item-key="id"
        show-select
        class="elevation-1"
        >
        </v-data-table>
  </div>

</template>

<script>

  export default {
    name: 'TableMbrand',
    props: {
       

       
    },
    components: {

    },
    data () {
      return {
        selected: [],
        headers: [
          { text: 'id', value: 'id',
          },
          { text: '브랜드명', value: 'name' },
          
        ],
        items: [],
       
      }
    },
    created () {

      this.getBrands()
    },
    
    watch: {
      selected: function (val) {
        this.$log.warn('selected' , val)
        if (val.length > 0) {
          this.$emit('onBrandChange', val[0])
          this.$store.dispatch('danawaBrand', val[0])
        } else {
          this.$emit('onBrandChange', null)
          this.$store.dispatch('danawaBrand', null)
        }
        
      },
    },
    methods: {
     
      async getBrands() {
        this.$log.warn('getbrands')
        const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
                // body: JSON.stringify(this.payload)
            };
            //this.overlay = true
            // const response = await fetch("http://localhost:5000/api/danawa/brand", requestOptions);
            const response = await fetch("http://localhost:5000/api/public/brands", requestOptions);

            const rv  = await response.json();
            this.$log.warn('rv', rv)
            this.items = rv.data
           
            //this.overlay = false
      }
    }
  }
  

</script>
