<template>
  <div>
    <v-btn @click="onRefresh">새로고침-{{this.brandId}}</v-btn>
    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        

        single-select
        item-key="id"
        show-select
        class="elevation-1"
        >
        </v-data-table>
  </div>

</template>

<script>

  export default {
    name: 'TableMbrand',
    props: {
      brandId:{
        type: Object,
        default: function () {
          return {  }
        }
      },
      danawaModel:{
        type: Object,
        default: function () {
          return {  }
        }
      }

       
    },
    components: {

    },
    
    data () {
      return {
        selected: [],
        headers: [
          { text: 'id', value: 'id',
          },
          { text: '모델명', value: 'name' },
          { text: '다나와모데', value: 'dmodelId' },
          { text: '다나와브랜드', value: 'dbrandId' },
          
        ],
        items: [],
       
      }
    },
    created () {

      // this.getBrands()
    },
    
    watch: {
      selected: function (val) {
        this.$log.warn('selected' , val)
        if (val.length > 0) {
          this.$emit('onModelChange', val[0])
          this.$store.dispatch('modealModel', val[0])
        } else {
          this.$emit('onModelChange', null)
          this.$store.dispatch('modealModel', null)
        }
        
      },
      brandId: function (val) {
        this.$log.warn('brandid - change', val)
        this.getModels(val)
        
      },
    },
    methods: {
      onRefresh() {
        this.getModels(this.brandId)
      },
      async getModels(brandId) {
        this.$log.warn('getModels')
        const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
                // body: JSON.stringify(this.payload)
            };
            //this.overlay = true
            const url = `http://localhost:5000/api/modeal/model/${brandId}`
            const response = await fetch(url, requestOptions);

            const rv  = await response.json();
            this.$log.warn('rv', rv)
            this.items = rv.data
           
            //this.overlay = false
      }
    }
  }
  

</script>
