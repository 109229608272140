<template>

    <v-card>
        <v-card-text>
            모두가딜러 차량정보
        </v-card-text>
        <v-card-text>
          <template>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  브랜드 <span v-if="brand">- {{brand.name}}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <table-mbrand  @onBrandChange="onBrandChange" />

                 

                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  모델 <span v-if="model">- {{model.name}}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <table-mmodel :brandId="brandId"  @onModelChange="onModelChange" />

                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  라인업
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels>
          </template>
        </v-card-text>
    </v-card>

</template>

<script>
  // import TableData from './TableData'
  import TableMbrand from './TableMbrand'
  import TableMmodel from './TableMmodel'
  export default {
    name: 'ModealCar',
    components: {
      // TableData,
      TableMbrand,
      TableMmodel
    },
    data () {
      return {
        selected: [],
        headerBrand: [
          { text: 'id', value: 'id'},
          { text: 'name', value: 'name' },
          
        ],
        itemsBrand: [],
        brand: null,
        model: null,
        brandId: null,
      }
    },
    created () {

      this.getBrands()
    },
    methods: {
      async getBrands() {
        this.$log.warn('getbrands')
        const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
                // body: JSON.stringify(this.payload)
            };
            //this.overlay = true
            const response = await fetch("http://localhost:5000/api/modeal/brand", requestOptions);

            const rv  = await response.json();
            this.$log.warn('rv', rv)
            this.itemsBrand = rv.data
           
            //this.overlay = false
      },
      onBrandChange(val) {
        this.brand = val
        if (this.brand) {
          this.brandId = this.brand.id
        } else {
          this.brandId = null
        }
        
        this.$log.warn('barnd2', this.brand)
      },
      onModelChange(val) {
        this.model = val
        this.$log.warn('model2', this.model)
      }
    }
  }
  

</script>
